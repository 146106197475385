import React, { useState, useEffect } from 'react';
import './App.css';

interface Report {
  name: string;
  lastModified: string;
  sizeInBytes: number;
  type: 'normal' | 'maintenance';
}

interface Location {
  path: string;
  reports: Report[];
}

interface ReportItemProps {
  name: string;
  lastModified: string;
  sizeInBytes: number;
  type: 'normal' | 'maintenance';
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
};

const formatBytes = (bytes: number) => {
  return bytes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ReportItem: React.FC<ReportItemProps> = ({ name, lastModified, sizeInBytes, type }) => {
  const displayName = name.replace(/\.(G4R|G4M)$/, '');
  return (
    <div className={`report-item ${type === 'maintenance' ? 'maintenance' : ''}`}>
      <span>{displayName}</span>
      <div>
        <span className="date">{formatDate(lastModified)}</span>
        <span className="size">{formatBytes(sizeInBytes)} bytes</span>
      </div>
    </div>
  );
};

const ReportKey: React.FC = () => (
  <div className="report-key">
    <span className="normal-report">Normal reports</span> | <span className="maintenance-report">Maintenance reports</span>
  </div>
);

interface LocationCardProps {
  path: string;
  reports: Report[];
  onMove?: () => void;
  isNewLocation: boolean;
}

const LocationCard: React.FC<LocationCardProps> = ({ path, reports, onMove, isNewLocation }) => (
  <div className="location-card">
    <div className="location-header">
      <span>{path}</span>
      {!isNewLocation && onMove && (
        <button onClick={onMove}>Move/Update</button>
      )}
    </div>
    <div className="location-content">
      <h3>Reports:</h3>
      {reports.map((report, index) => (
        <ReportItem key={index} {...report} />
      ))}
    </div>
    <ReportKey />
  </div>
);

interface AlertProps {
  children: React.ReactNode;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ children, onClose }) => (
  <div className="alert">
    {children}
    <button onClick={onClose} className="close-button">×</button>
  </div>
);

const defaultReports: Report[] = [
  { name: 'Best Sellers by Shop Category.G4R', lastModified: '2023-11-01', sizeInBytes: 5000, type: 'normal' },
  { name: 'Customer Order Summary.G4R', lastModified: '2023-10-20', sizeInBytes: 6000, type: 'normal' },
  { name: 'Dead stock.G4R', lastModified: '2023-09-05', sizeInBytes: 4000, type: 'normal' },
  { name: 'EANs by Sales Invoice.G4R', lastModified: '2023-12-01', sizeInBytes: 8000, type: 'normal' },
  { name: 'Full Details of All Products In Stock.G4R', lastModified: '2023-08-15', sizeInBytes: 10000, type: 'normal' },
  { name: 'Change Product Title Case.G4M', lastModified: '2023-07-01', sizeInBytes: 3000, type: 'maintenance' }
];

function App() {
  const [scanning, setScanning] = useState<boolean>(false);
  const [showInitialMessage, setShowInitialMessage] = useState<boolean>(true);
  const [showAdditionalMessage, setShowAdditionalMessage] = useState<boolean>(false);
  const [showMoveMessage, setShowMoveMessage] = useState<boolean>(false);
  const [oldLocations, setOldLocations] = useState<Location[]>([
    {
      path: 'C:\\Gardlink4\\G4Reporter\\',
      reports: [
        { name: 'Best Sellers by Shop Category.G4R', lastModified: '2024-01-15', sizeInBytes: 5120, type: 'normal' },
        { name: 'Book Stock Out-In Reprint.G4R', lastModified: '2024-02-01', sizeInBytes: 7168, type: 'normal' },
        { name: 'Customer Order Summary.G4R', lastModified: '2024-01-20', sizeInBytes: 6144, type: 'normal' },
        { name: 'Dead stock.G4R', lastModified: '2024-01-05', sizeInBytes: 4096, type: 'normal' },
        { name: 'EANs by Sales Invoice.G4R', lastModified: '2024-02-10', sizeInBytes: 8192, type: 'normal' },
        { name: 'Full Details of All Products In Stock.G4R', lastModified: '2024-01-25', sizeInBytes: 10240, type: 'normal' },
        { name: 'QuickBooksOnline - Last Month\'s Sales Export.G4R', lastModified: '2024-03-01', sizeInBytes: 9216, type: 'normal' },
        { name: 'Change Customer Title Case.G4M', lastModified: '2024-02-15', sizeInBytes: 3500, type: 'maintenance' },
        { name: 'Change Product Title Case.G4M', lastModified: '2024-02-20', sizeInBytes: 3200, type: 'maintenance' }
      ]
    }
  ]);
  const [newLocation, setNewLocation] = useState<Location>({
    path: 'C:\\GardlinkSuite\\G4Reporter\\',
    reports: []
  });

  useEffect(() => {
    const timer = setTimeout(() => setShowInitialMessage(false), 10000);
    return () => clearTimeout(timer);
  }, []);

  const handleScan = () => {
    setScanning(true);
    setShowInitialMessage(false);
    setTimeout(() => {
      setScanning(false);
      setOldLocations([...oldLocations, {
        path: 'C:\\Gardlink Installation\\G4Reporter\\',
        reports: defaultReports
      }]);
      setShowAdditionalMessage(true);
      setTimeout(() => setShowAdditionalMessage(false), 10000);
    }, 2000);
  };

  const handleAddLocation = () => {
    const newPath = prompt("Enter the path of the G4Reporter installation:");
    if (newPath) {
      setOldLocations([...oldLocations, { 
        path: newPath, 
        reports: defaultReports
      }]);
    }
  };

  const handleMove = (index: number) => {
    const updatedOldLocations = [...oldLocations];
    const movedLocation = updatedOldLocations.splice(index, 1)[0];
    
    const updatedReports = [...newLocation.reports];
    movedLocation.reports.forEach(report => {
      const existingReport = updatedReports.find(r => r.name === report.name);
      if (!existingReport || new Date(report.lastModified) > new Date(existingReport.lastModified)) {
        const reportIndex = updatedReports.findIndex(r => r.name === report.name);
        if (reportIndex !== -1) {
          updatedReports[reportIndex] = report;
        } else {
          updatedReports.push(report);
        }
      }
    });
    
    setOldLocations(updatedOldLocations);
    setNewLocation({
      ...newLocation,
      reports: updatedReports.sort((a, b) => a.name.localeCompare(b.name))
    });
    setShowMoveMessage(true);
  };

  return (
    <div className="App">
      <h1>G4Reporter Standardisation Tool</h1>
      {showInitialMessage && (
        <Alert onClose={() => setShowInitialMessage(false)}>
          G4Reporter has been located in the 'Gardlink v168' location (C:\Gardlink4\G4Reporter\).
          You can check the whole C: drive for additional installations using the 'Scan C: Drive' button.
        </Alert>
      )}
      {showAdditionalMessage && (
        <Alert onClose={() => setShowAdditionalMessage(false)}>
          An additional G4Reporter installation has been found at C:\Gardlink Installation\G4Reporter\.
        </Alert>
      )}
      {showMoveMessage && (
        <Alert onClose={() => setShowMoveMessage(false)}>
          The reports have been moved, merged, and updated in the new location.
        </Alert>
      )}
      <div className="container">
        <div className="column">
          <h2>Old Location(s)</h2>
          <div className="button-group">
            <button onClick={handleScan} disabled={scanning}>
              {scanning ? 'Scanning C: Drive...' : 'Scan C: Drive'}
            </button>
            <button onClick={handleAddLocation}>Add Location</button>
          </div>
          <div className="scroll-area">
            {oldLocations.map((location, index) => (
              <LocationCard
                key={index}
                {...location}
                onMove={() => handleMove(index)}
                isNewLocation={false}
              />
            ))}
          </div>
        </div>
        <div className="column">
          <h2>New Location</h2>
          <LocationCard {...newLocation} isNewLocation={true} />
        </div>
      </div>
      <div className="disclaimer">
        <p>This is a demo of the user interface to show what a proposed application will do. None of the actions described above have actually taken place on anyone's computer.</p>
      </div>
    </div>
  );
}

export default App;
